export default function initFormsFunctions() {
	const formSelectFields = document.querySelectorAll(
		'.cdb_form_field._block_select'
	);

	const inputLotElement = document.getElementById('LotCode3');

	if (inputLotElement) {
		inputLotElement.setAttribute('autocomplete', 'lot-code');
	  }

	if (!formSelectFields.length) {
		return;
	}

	// if select is on the page, check for the title and add it as a disabled first option
	formSelectFields.forEach((select) => {
		const label = select.querySelector('label').dataset.title;
		if (label && label != '') {
			const option = document.createElement('option');
			option.text = label;
			option.value = '';
			option.disabled = true;
			option.selected = true;
			select.querySelector('select').appendChild(option);
		}
	});
}
