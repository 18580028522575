// import {fadeIn} from '@pernod-ricard-global-cms/jsanimations';

export default function siteHeaderJs(options = {}) {
	try {
		// CSS for site-header should usually be included in critical.scss
		const {block} = options;
		// fadeIn(block);

		const documentBody = document.body;
		const navContainer = document.querySelector('.header-nav');
		const hamburgerWrapper = document.querySelector(
			'.header-interactions__hamburger-wrapper'
		);
		const hamburger = document.querySelector(
			'.header-interactions__hamburger'
		);
		const hamburgerBars = hamburger.querySelectorAll(
			'.header-interactions__hamburger-bar'
		);
		const hamburgerClose = document.querySelector(
			'.header-interactions__menu-logo-close'
		);
		const subnavClose = document.querySelectorAll(
			'.header-nav__sub-nav-title-back'
		);

		const menuItems = document.querySelectorAll('.menu-item');

		const itemsWithChildren = navContainer.querySelectorAll(
			'a.menu-item-has-children'
		);
		const mainNavItem = document.querySelectorAll('.header-nav__item');

		if (!navContainer) return;

		subnavClose.forEach(function (item) {
			item.addEventListener('click', function () {
				closeSubnavs();
			});
		});

		const closeSubnavs = () => {
			navContainer
				.querySelectorAll('.header-nav__item.active')
				.forEach((el) => el.classList.remove('active'));

			navContainer
				.querySelectorAll('.header-nav__container.sub-active')
				.forEach((el) => el.classList.remove('sub-active'));
		};

		// Set aria-hidden to true if mobile user
		if (window.innerWidth < 768) {
			navContainer.ariaHidden = 'true';
		}
		// Reveal and hide subnav
		menuItems.forEach((item) => {
			let subNav = item.querySelector('.header-nav__sub-nav');
			if (subNav) {
				item.addEventListener('mouseover', function (e) {
					e.preventDefault;
					subNav.style.display = 'block';
				});
				item.addEventListener('mouseout', function (e) {
					e.preventDefault;
					subNav.style.display = 'none';
				});
				// Event to reveal and hide subnav for keyboard users
				item.addEventListener('keydown', function (e) {
					e.preventDefault;
					if (e.key == ' ') {
						subNav.style.display = 'block';
					}
				});
				item.addEventListener('keydown', function (e) {
					e.preventDefault;
					if (e.key == 'Escape') {
						subNav.style.display = 'none';
					}
				});
			}
		});

		hamburgerWrapper.addEventListener('click', function () {
			// Set aria-hidden to false if mobile nav is active
			navContainer.ariaHidden = 'false';
			hamburger.classList.remove('active');
			hamburgerBars.forEach((item) => {
				item.classList.toggle('active');
			});
			documentBody.classList.add('mobile-menu-active');
			// documentBody.classList.toggle('mobile-menu-active');
		});

		hamburgerClose.addEventListener('click', function () {
			hamburger.classList.remove('active');
			documentBody.classList.remove('mobile-menu-active');
		});

		itemsWithChildren.forEach((item) => {
			if (window.innerWidth < 1024) {
				item.addEventListener('click', function (e) {
					e.preventDefault();
					item.parentNode.classList.toggle('active');
					item.parentNode.parentNode.classList.toggle('sub-active');
				});
			}
		});

		mainNavItem.forEach((item) => {
			let itemLink = item.querySelector('a.menu-item-has-children');
			if (itemLink != null) {
				item.addEventListener('focusin', function () {
					itemLink.setAttribute('aria-expanded', 'true');
					itemLink.nextElementSibling.setAttribute(
						'aria-expanded',
						'true'
					);
				});

				item.addEventListener('focusout', function () {
					itemLink.setAttribute('aria-expanded', 'false');
					itemLink.nextElementSibling.setAttribute(
						'aria-expanded',
						'false'
					);
				});
			}
		});

		window.addEventListener('resize', closeSubnavs, true);
	} catch (error) {
		console.error(error);
	}
}
