import jsUtils from '@pernod-ricard-global-cms/jsutils';
import {optinUS} from 'Assets/js/modules/form-utils';

// Adds a tick mark to all form fields in the newsletter form
// that contain the class cdb_form_field_is_valid.
// Exceptions include the consent checkbox (no tick mark) and the DOB row
// which has its own logic that checks if the user has input
// a valid date and adds the tick mark to the end of the DOB row

const putCheckMarks = (form) => {
	if (form) {
		const allInputsContainers = form.querySelectorAll('.cdb_form_field');

		if (allInputsContainers) {
			allInputsContainers.forEach((inputContainer) => {
				const input = inputContainer.querySelector('input, select');

				if (input) {
					if (inputContainer.classList.contains('choice')) {
						const checkboxes = form.querySelectorAll(
							'.cdb_form_options input'
						);
						if (checkboxes) {
							checkboxes.forEach((elem) => {
								elem.addEventListener('input', () =>
									simpleCheck(elem, inputContainer)
								);
							});
						}
					} else if (
						inputContainer.classList.contains(
							'_block_country_select'
						) ||
						inputContainer.classList.contains(
							'_block_date_of_birth'
						)
					) {
						input.addEventListener('input', () =>
							simpleCheck(input, inputContainer)
						);
					} else {
						input.addEventListener('input', () =>
							checkTextField(input, inputContainer)
						);
					}
				}
			});
		}
	}
};

const checkTextField = (elem, container) => {
	if (elem.type === 'email') {
		if (!jsUtils.isEmailValid(elem.value)) {
			container.classList.remove('cdb_form_field_is_valid');
		} else {
			container.classList.add('cdb_form_field_is_valid');
		}
	}

	if (elem.type === 'text') {
		console.log("it's text", elem.value.length);
		if (elem.value.length >= 2) {
			container.classList.add('cdb_form_field_is_valid');
		} else {
			container.classList.remove('cdb_form_field_is_valid');
		}
	}

	validateForm();
};

const simpleCheck = (elem, container) => {
	if (elem.value) {
		container.classList.add('cdb_form_field_is_valid');
	} else {
		container.classList.remove('cdb_form_field_is_valid');
	}
	validateForm();
};

const validateForm = () => {
	const form = document.querySelector('.newsletter-modal form');
	const popupSubmitButton = form.querySelector('.cdb-submit');
	let isValid = true;
	const allInputsContainers = form.querySelectorAll('.cdb_form_field');

	allInputsContainers.forEach((inputContainer) => {
		const input = inputContainer.querySelector(
			'input[text],input[email],input[date], select'
		);
		if (input) {
			if (!inputContainer.classList.contains('cdb_form_field_is_valid')) {
				isValid = false;
			}
		}
	});

	// Check all checkboxes to see if one is checked
	const checkboxes = form.querySelectorAll('.cdb_form_options input');
	let checked = false;
	if (checkboxes) {
		for (let i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked) {
				checked = true;
			}
		}
	}

	if (!checked) {
		isValid = false;
	}

	if (!form.querySelector('.optin input:checked')) {
		isValid = false;
	}

	if (isValid) {
		popupSubmitButton.classList.add('valid');
		popupSubmitButton.disabled = false;
	} else {
		popupSubmitButton.classList.remove('valid');
		popupSubmitButton.disabled = true;
	}
};

const getSubstringAfterDash = (str) => {
	let parts = str.split('-');
	return parts.length > 1 ? parts.slice(1).join('-') : '';
};

const setUserCountry = (form) => {
	if (form) {
		const currentMarket = document.documentElement.lang;
		const countrySelectorContainer = form.querySelector(
			'._block_country_select'
		);
		const input = countrySelectorContainer.querySelector('select');
		const countrySelectorOptions = form.querySelectorAll(
			'._block_country_select select option'
		);
		let currentMarketAfterDash = getSubstringAfterDash(currentMarket);

		if (countrySelectorOptions) {
			// select user's country in the country selector
			countrySelectorOptions.forEach((option) => {
				if (option.value.toLowerCase() === currentMarketAfterDash) {
					option.setAttribute('selected', 'selected');
					simpleCheck(input, countrySelectorContainer);
				}
			});
		}
	}
};

function toggleCdbForm(event) {
	event.preventDefault();
	const cdbFormContainer = document.querySelector('.newsletter-modal');
	const newsletterCloseButton = cdbFormContainer.querySelector(
		'.newsletter__container-logo-close'
	);
	// const popupSubmitButton = document.querySelector('.cdb-submit');
	if (cdbFormContainer) {
		cdbFormContainer.classList.toggle('newsletter__container--hide');
		cdbFormContainer.classList.toggle('newsletter__container--show');
		cdbFormContainer.toggleAttribute('inert');

		cdbFormContainer.setAttribute('aria-modal', 'true');

		if (newsletterCloseButton) {
			newsletterCloseButton.classList.toggle('active');
			newsletterCloseButton.focus();
		}
		putCheckMarks(cdbFormContainer.querySelector('form'));
		// popupSubmitButton.disabled = true;
	}
}

export function setEmailInForm(email) {
	const cdbFormContainer = document.querySelector('.newsletter-modal');
	const mailField = cdbFormContainer.querySelector('input[type=email]');
	if (mailField) {
		mailField.value = email;
		checkTextField(mailField, mailField.closest('.cdb_form_field'));
	}
}

export function newsletterInit() {
	const newsletterButtons = document.querySelectorAll('[cdb-form-trigger]');

	if (!newsletterButtons.length) {
		return;
	}
	newsletterButtons.forEach((newsletterButton) => {
		newsletterButton.addEventListener('click', toggleCdbForm);
	});

	const cdbFormContainer = document.querySelector('.newsletter-modal');

	if (cdbFormContainer) {
		const newsletterCloseButton = cdbFormContainer.querySelector(
			'.newsletter__container-logo-close'
		);
		newsletterCloseButton.addEventListener('click', toggleCdbForm);
		newsletterCloseButton.addEventListener('click', () => {
			newsletterButtons[0].focus();
		});
		const popupForm = cdbFormContainer.querySelector('form');
		putCheckMarks(popupForm);
		setUserCountry(popupForm);
		optinUS();
	}
}
