export function optinUS() {
	// US Newsletter Local Attribute Only
	const localAttributeCrossBand = document.querySelector(
		'div.hidden._block_local_attribute_text_input input'
	);

	if (localAttributeCrossBand) {
		const checkbox = document.querySelector('.cdb_form_field.optin input');

		checkbox.addEventListener('change', () => {
			if (checkbox.checked) {
				console.log( 'OPTIN', checkbox.checked );
				localAttributeCrossBand.value = 1;
			} else {
				localAttributeCrossBand.value = 0;
			}

			console.log( 'CROSSBAND', localAttributeCrossBand.value );
		});
	}
}